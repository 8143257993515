import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-blog-starter/src/templates/single-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`When to use Tanween? (متى نستخدم التنوين)`}</h2>
    <p>{`We use it with الاسم when it is`}</p>
    <ul>
      <li parentName="ul">{`المفرد`}</li>
      <li parentName="ul">{`المنصرف `}</li>
      <li parentName="ul">{`إذا درجنا (when it comes in middle of sentence)`}</li>
    </ul>
    <br />
    <p>{`For e.g. رأيت رجلاً في البيت`}</p>
    <h2>{`When to drop Tanween (متى نسقط التنوين)`}</h2>
    <ul>
      <li parentName="ul">{`عند الإضافة`}
        <ul parentName="li">
          <li parentName="ul">{`e.g. جاء طالبُ العلمِ إلى البيت`}</li>
        </ul>
      </li>
      <li parentName="ul">{`(عند دخول (ال`}
        <ul parentName="li">
          <li parentName="ul">{`e.g. جاء الطالبُ إلى البيت`}</li>
        </ul>
      </li>
    </ul>
    <h2>{`Reference`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.qutoofacademy.com/",
        "rel": "nofollow noopener"
      }}>{`Qutoof Academy`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      